/** @format */

import { isSameMonth, isToday, getLocalTimeZone } from '@internationalized/date';
import React from 'react';
import styled from '@emotion/styled';
import { AriaCalendarCellProps, CalendarCellAria, DateValue, useCalendarCell } from 'react-aria';
import { CalendarState } from 'react-stately';
import { cssVars } from '@atoms/GlobalStyles';
import { motion } from 'framer-motion';

const StyledCalendarCell = styled.td<CalendarCellAria & { isOutsideMonth: boolean; isToday: boolean }>`
  text-align: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  width: 38.57px;
  height: 38.57px;
  border-radius: 19.29px;
  background-color: ${({ isSelected }) => (isSelected ? '#006edc' : null)};
  color: ${({ isSelected }) => (isSelected ? 'white' : null)};
  //visibility: ${({ isOutsideMonth }) => (isOutsideMonth ? 'hidden' : 'visible')};
  opacity: ${({ isDisabled, isOutsideMonth }) => (isDisabled || isOutsideMonth ? 0.25 : 1)};

  position: relative;
  outline-color: ${cssVars.blue};
  :before {
    content: ' ';
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: ${cssVars.red};
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
    display: ${({ isToday }) => (isToday ? 'flex' : 'none')};
  }

  @media (hover: hover) {
    :hover {
      background-color: ${({ isDisabled }) => (isDisabled ? 'transparent' : cssVars.blue)};
      ${({ isDisabled }) => (isDisabled ? '' : 'color: white')};
    }
  }
`;

function CalendarCell({ state, date, currentMonth }: AriaCalendarCellProps & { state: CalendarState; currentMonth: DateValue }) {
  const ref = React.useRef(null);
  const calendarCellAria = useCalendarCell({ date }, state, ref);
  const isOutsideMonth = !isSameMonth(currentMonth, date);
  const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, isUnavailable, formattedDate } = calendarCellAria;
  // const today = isToday(date, getLocalTimeZone())
  // console.log(cellProps, buttonProps);
  return (
    <StyledCalendarCell
      {...calendarCellAria}
      {...cellProps}
      {...buttonProps}
      isOutsideMonth={isOutsideMonth}
      ref={ref}
      isToday={isToday(date, getLocalTimeZone())}
      // layoutId={'date'}
    >
      {formattedDate}
    </StyledCalendarCell>
  );
}

export default CalendarCell;
