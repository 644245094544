/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

interface StyledCalendarProps {
  show: boolean;
  up?: boolean;
  left?: boolean;
}

export const StyledCalendar = styled.div<StyledCalendarProps>`
  margin-top: ${({ up }) => (up ? '-352px' : '8px')};
  margin-left: ${({ left }) => (left ? '-200px' : '0')};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: ${cssVars.white};
  border-radius: 3px;
  position: absolute;
  padding: 8px;
  user-select: none;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  z-index: 100;
`;
