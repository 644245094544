/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledMonthYearControl = styled.div`
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  height: 32px;
  padding: 0 8px;
  justify-content: center;
  align-items: center;

  @media (hover: hover) {
    :hover {
      background-color: ${cssVars.blue};
      color: white;
    }
  }
`;
