/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDateSegment } from 'react-aria';
import { DateFieldState, DateSegment as IDateSegment } from 'react-stately';
import { css } from '@emotion/react';

interface DateSegmentProps {
  state: DateFieldState;
  segment: IDateSegment;
}

const StyledDateSegment = styled.div<{ isPlaceholder?: boolean }>`
  font-size: 1.2rem;
  padding: 2px;
  display: flex;
  justify-content: space-evenly;

  //min-width: 24px;
`;

function DateSegment({ segment, state, ...props }: DateSegmentProps) {
  let ref = React.useRef(null);
  let { segmentProps } = useDateSegment(segment, state, ref);
  const isEditable = ['minute', 'hour'].includes(segment.type);

  // useEffect(() => {
  //   console.log(state, segment, segmentProps);
  // }, [state, segment]);

  return (
    <StyledDateSegment
      {...segmentProps}
      ref={ref}
      isPlaceholder={segment.isPlaceholder}
      onWheel={(event) => {
        event.stopPropagation();
        event.preventDefault();
        if (['minute', 'hour'].includes(segment.type)) {
          console.log(state);
          event.deltaY < 0 ? state.increment(segment.type) : state.decrement(segment.type);
        }
        // console.log(segment);
      }}
      css={css`
        min-width: ${!isEditable ? '' : '24px'};
        //background:
        &:hover {
          background: ${!isEditable ? '' : cssVars.lightBlue};
        }
        &:focus-within {
          border-color: ${!isEditable ? '' : cssVars.blue};
          outline-color: ${!isEditable ? 'transparent' : cssVars.blue};
          background: ${!isEditable ? '' : cssVars.blue};
          color: ${!isEditable ? '' : cssVars.white};
        }
      `}
    >
      {segment.text}
    </StyledDateSegment>
  );
}

export default DateSegment;
