/** @format */

export enum Weekday {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export const WeekdayFns = {
  [Weekday.Sunday]: 0,
  [Weekday.Monday]: 1,
  [Weekday.Tuesday]: 2,
  [Weekday.Wednesday]: 3,
  [Weekday.Thursday]: 4,
  [Weekday.Friday]: 5,
  [Weekday.Saturday]: 6,
};

export const WeekdayShort = {
  [Weekday.Sunday]: 'Su',
  [Weekday.Monday]: 'Mo',
  [Weekday.Tuesday]: 'Tu',
  [Weekday.Wednesday]: 'We',
  [Weekday.Thursday]: 'Th',
  [Weekday.Friday]: 'Fr',
  [Weekday.Saturday]: 'Sa',
};

export enum Month {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export const MonthFns = {
  [Month.January]: 0,
  [Month.February]: 1,
  [Month.March]: 2,
  [Month.April]: 3,
  [Month.May]: 4,
  [Month.June]: 5,
  [Month.July]: 6,
  [Month.August]: 7,
  [Month.September]: 8,
  [Month.October]: 9,
  [Month.November]: 10,
  [Month.December]: 11,
};
