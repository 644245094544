/** @format */

import { getLocalTimeZone, now, parseAbsoluteToLocal } from '@internationalized/date';
import { useMemo, useState } from 'react';
import { DateValue } from 'react-aria';

export default function useDatePickerValue(initialValue?: Date): [DateValue, (value: DateValue) => void, () => Date] {
  const [_date, _setDate] = useState<Date>(initialValue ?? new Date());

  const setDate = (value: DateValue) => {
    _setDate(value ? value.toDate(getLocalTimeZone()) : new Date());
  };

  const date = useMemo(() => parseAbsoluteToLocal(_date.toISOString()), [_date]);

  const getDate = () => {
    return _date;
  };

  return [date, setDate, getDate];
}
