/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledWeekday = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 38.57px;
  height: 38.57px;
  border-radius: 19.29px;

  @media (hover: hover) {
    :hover {
      background-color: ${cssVars.blue};
      color: white;
    }
  }
`;
