/** @format */

import styled from '@emotion/styled';

export const StyledMonthYear = styled.div`
  display: flex;
  flex-direction: row;
  color: #2c3557;
  font-size: 14pt;
  font-weight: bold;
  height: 48px;
  justify-content: space-between;
  align-items: center;

  > *:first-of-type {
    margin-left: 8px;
  }

  > *:last-child {
    margin-right: 8px;
  }
`;
