/** @format */

import DateSegment from '@atoms/DatePicker/DateSegment';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { TimePickerProps, TimeValue } from '@react-types/datepicker';
import React from 'react';
import styled from '@emotion/styled';
import { useLocale, useTimeField } from 'react-aria';
import { useTimeFieldState } from 'react-stately';

interface TimeFieldProps<T extends TimeValue = TimeValue> extends TimePickerProps<T> {
  label: string;
}

const TimeFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
`;

const StyledTimeField = styled.div`
  display: flex;
  padding: 4px;
`;

function TimeField(props: TimeFieldProps) {
  const { locale } = useLocale();
  const state = useTimeFieldState({
    ...props,
    hourCycle: props.hourCycle ?? 24,
    locale,
  });

  const ref = React.useRef(null);
  const { labelProps, fieldProps } = useTimeField(props, state, ref);

  return (
    <TimeFieldWrapper>
      <span {...labelProps} aria-label={!!props.label.length ? props.label : 'Select Time'}>
        {props.label}
      </span>
      <StyledTimeField {...fieldProps} ref={ref} className="field">
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}
        {state.validationState === 'invalid' && <span aria-hidden="true">🚫</span>}
      </StyledTimeField>
    </TimeFieldWrapper>
  );
}

export default TimeField;
