/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledMonth = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  height: 48px;
  width: 90px;
  cursor: pointer;
  background: ${({ isSelected }) => (isSelected ? cssVars.blue : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? cssVars.white : cssVars.blue)};

  @media (hover: hover) {
    :hover {
      background: ${({ isSelected }) => (isSelected ? cssVars.blue : cssVars.lightBlue)};
      color: ${({ isSelected }) => (isSelected ? cssVars.white : cssVars.blue)};
    }
  }
`;
