/** @format */

import TimeField from '@atoms/DatePicker/TimeField';
import { getLocalTimeZone, parseAbsoluteToLocal, Time, today } from '@internationalized/date';
import { TimeValue } from '@react-types/datepicker';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDatePickerState } from 'react-stately';
import { AriaDatePickerProps, DateValue, useDatePicker, useDialog } from 'react-aria';
import Popover from './Popover';
import Calendar from './DatePicker/Calendar';
import Button from './Button';

interface DateTimePickerProps extends AriaDatePickerProps<DateValue> {
  timePicker?: boolean;
  // date: Date;
  // setDate: Dispatch<SetStateAction<Date>>;
}

const StyledDateTimePicker = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const Dialog = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonLabel = styled.div`
  //padding: 2px;
  margin: 0;
`;

const formatDateValue = (date: DateValue) => {
  return `${date.day}/${date.month}/${date.year}`;
};

const formatTimeValue = (time: TimeValue) => {
  return `${time.hour}:${time.minute.toString().padStart(2, '0')}`;
};

function DateTimePicker(props: DateTimePickerProps) {
  const todayValue = today(getLocalTimeZone());
  const defaultValue = props.defaultValue ?? todayValue;
  const [focusedValue, setFocusedValue] = useState(props.value ?? defaultValue);

  const state = useDatePickerState({ ...props, shouldCloseOnSelect: !props.timePicker, granularity: props.timePicker ? 'minute' : 'day' });
  const dialogRef = React.useRef(null);
  const ref = React.useRef(null);
  const { groupProps, labelProps, fieldProps, buttonProps, dialogProps: pickerDialogProps, calendarProps } = useDatePicker(props, state, ref);

  const { dialogProps } = useDialog(
    {
      ...pickerDialogProps,
      role: 'dialog',
    },
    dialogRef,
  );
  return (
    <StyledDateTimePicker>
      <div {...labelProps}>{props.label}</div>
      <div {...groupProps} ref={ref} style={{ display: 'flex' }}>
        {/*<DateField {...fieldProps} />*/}
        <Button {...buttonProps} onClick={state.toggle}>
          <ButtonLabel>{formatDateValue(props.value ?? defaultValue)}</ButtonLabel>
          {props.timePicker && <ButtonLabel>, {formatTimeValue(state.timeValue)}</ButtonLabel>}
        </Button>
      </div>
      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom start">
          {/*<Popover state={state} triggerRef={ref} placement="bottom start">*/}
          <Dialog {...dialogProps}>
            <Calendar
              {...calendarProps}
              focusedValue={focusedValue}
              onFocusChange={(date) => {
                setFocusedValue(date);
              }}
            />
            {props.timePicker && <TimeField label={''} value={state.timeValue} onChange={state.setTimeValue} />}
          </Dialog>
        </Popover>
      )}
    </StyledDateTimePicker>
  );
}

export default DateTimePicker;
