/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledCloseMonthYear = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      color: ${cssVars.darkGrey};
    }
  }
`;
