/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledYears = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 84px);
  color: ${cssVars.blue};
  max-height: 231px;
  padding-left: 1px;
`;
