/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

interface DayProps {
  first?: number;
  selected: boolean;
  today: boolean;
}

export const StyledDay = styled.div<DayProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 38.57px;
  height: 38.57px;
  border-radius: 19.29px;
  background-color: ${({ selected }) => (selected ? '#006edc' : null)};
  color: ${({ selected }) => (selected ? 'white' : null)};

  :before {
    content: ' ';
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: ${cssVars.red};
    position: absolute;
    margin-bottom: -22px;
    margin-right: 0;
    display: ${({ today }) => (today ? 'flex' : 'none')};
  }

  @media (hover: hover) {
    :hover {
      background-color: ${cssVars.blue};
      color: white;
    }
  }

  ${({ first }) => (Number.isInteger(first) ? `grid-column: ${first};` : null)}
`;
