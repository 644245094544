/** @format */

import styled from '@emotion/styled';

export const StyledDatePicker = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;

  > input {
    padding: 8px;
  }
`;
