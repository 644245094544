/** @format */

import { StyledMonth, StyledMonths, StyledMonthYearControl, StyledMonthYearControls, StyledNavControl, StyledYear } from '@atoms/DatePicker/styled';
import { Month, MonthFns } from '@atoms/DatePicker/types';
import FlexBox from '@atoms/FlexBox';
import Text from '@atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalendarDate, createCalendar, getLocalTimeZone, today } from '@internationalized/date';
import { PressEvent } from '@react-types/shared';
import { setMonth } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { CalendarAria, CalendarProps, DateValue, useCalendar, useLocale } from 'react-aria';
import { FormattedDate, FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CalendarStateOptions, useCalendarState } from 'react-stately';
import Button from '@atoms/Button';
import CalendarGrid from './CalendarGrid';
import { cssVars } from '@atoms/GlobalStyles';
import Scrollbar from '@atoms/Scrollbar';
import { css } from '@emotion/react';

// interface CalendarProps {
//   minValue?: DateValue;
//   maxValue?: DateValue;
//
//   isUnavailable?(date: DateValue): boolean;
// }

const StyledCalendar = styled.div<CalendarAria['calendarProps']>`
  width: 100%;
  min-height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  box-sizing: border-box;
  * + * {
    user-select: none;
  }
`;

const SubMenu = styled(motion.div)`
  position: absolute;
  inset: 0;
  background: ${cssVars.white};
`;

const SubMenuList = styled.div`
  color: ${cssVars.blue};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
  min-height: 100%;
  //padding: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  font-size: 19px;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
`;

function Calendar(props: CalendarProps<DateValue>) {
  const [showMonths, setShowMonths] = useState(false);
  const [showYears, setShowYears] = useState(false);
  const [direction, setDirection] = useState<number>(1);
  const yearsScrollRef = useRef<HTMLElement>(null);
  const selectedYearRef = useRef<HTMLDivElement>(null);
  const prevDateRef = useRef<CalendarDate>(null);
  const { locale } = useRouter();
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);

  const getRelativeDirection = (date: CalendarDate) => {
    const diff = date.compare(prevDateRef.current);
    return Math.sign(diff);
  };

  useLayoutEffect(() => {
    if (yearsScrollRef.current && showYears) {
      const container = yearsScrollRef.current;
      container.scrollTop = selectedYearRef.current ? selectedYearRef.current.offsetTop : container.scrollHeight / 2;
    }
  }, [showYears]);

  useEffect(() => {
    prevDateRef.current = state.visibleRange.start;
  }, [state.visibleRange]);

  const month = title.split(' ')[0];
  const year = title.split(' ')[1];

  return (
    <StyledCalendar {...calendarProps}>
      <FlexBox flexDirection={'column'} gap={'8px'}>
        <Header>
          <StyledNavControl
            onClick={(e) => {
              setDirection(-1);
              prevButtonProps.onPress(e as any as PressEvent);
            }}
          >
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
          </StyledNavControl>
          <div
            css={css`
              display: flex;
            `}
          >
            <StyledMonthYearControl
              onClick={() => {
                setShowMonths((bool) => !bool);
                setShowYears(false);
              }}
            >
              {month}
            </StyledMonthYearControl>
            {/*<span>&nbsp;</span>*/}
            <StyledMonthYearControl
              onClick={() => {
                setShowYears((bool) => !bool);
                setShowMonths(false);
              }}
            >
              {year}
            </StyledMonthYearControl>
          </div>
          <StyledNavControl
            onClick={(e) => {
              setDirection(1);
              nextButtonProps.onPress(e as any as PressEvent);
            }}
          >
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </StyledNavControl>
        </Header>
        <CalendarGrid state={state} direction={direction} />
      </FlexBox>
      <FlexBox justifyContent={'space-around'} width={'100%'}>
        <Text.Link
          onClick={() => {
            state.setFocusedDate(state.value);
          }}
        >
          <FormattedMessage defaultMessage="Selected" description="Label for currently selected date" />
        </Text.Link>
        <Text.Link
          onClick={() => {
            state.setFocusedDate(today(getLocalTimeZone()));
          }}
        >
          <FormattedMessage defaultMessage="Today" description="Date picker today link" />
        </Text.Link>
      </FlexBox>
      <AnimatePresence>
        {showMonths && (
          <SubMenu initial={{ scale: 0, opacity: 0 }} exit={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }}>
            <SubMenuList>
              {Object.keys(Month).map((key, idx) => (
                <StyledMonth
                  isSelected={idx + 1 === state.focusedDate.month}
                  key={key}
                  onClick={() => {
                    const newDate = new CalendarDate(state.focusedDate.year, idx + 1, state.focusedDate.day);
                    state.setFocusedDate(newDate);
                    setDirection(getRelativeDirection(newDate));
                    setShowMonths(false);
                  }}
                >
                  <FormattedDate value={setMonth(new Date(), MonthFns[key as Month])} month="long" />
                </StyledMonth>
              ))}
            </SubMenuList>
          </SubMenu>
        )}
        {showYears && (
          <SubMenu initial={{ scale: 0, opacity: 0 }} exit={{ scale: 0, opacity: 0 }} animate={{ scale: 1, opacity: 1 }}>
            <Scrollbar ref={yearsScrollRef}>
              <SubMenuList>
                {Array.from(Array(99).keys()).map((key, i) => {
                  const year = new Date().getFullYear() - 50 + i;
                  const isSelected = year === state.focusedDate.year;
                  return (
                    <StyledYear
                      isSelected={isSelected}
                      key={key}
                      ref={isSelected ? selectedYearRef : null}
                      onClick={() => {
                        const newDate = new CalendarDate(year, state.focusedDate.month, state.focusedDate.day);
                        setDirection(getRelativeDirection(newDate));

                        state.setFocusedDate(newDate);
                        setShowYears(false);
                      }}
                    >
                      {year}
                    </StyledYear>
                  );
                })}
              </SubMenuList>
            </Scrollbar>
          </SubMenu>
        )}
      </AnimatePresence>
    </StyledCalendar>
  );
}

export default Calendar;
