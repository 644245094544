/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledMonths = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 90px);
  color: ${cssVars.blue};
  height: 231px;
`;
