/** @format */

import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

export const StyledWeekdays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 38.57px);
  grid-auto-rows: 38.57px;
  color: ${cssVars.blue};
`;
