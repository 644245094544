/** @format */

import CalendarCell from '@atoms/DatePicker/CalendarCell';
import { CalendarDate, getWeeksInMonth } from '@internationalized/date';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { AriaCalendarGridProps, CalendarGridAria, DateValue, useCalendarGrid, useLocale } from 'react-aria';
import { CalendarState } from 'react-stately';
import { useRouter } from 'next/router';

const StyledCalendarGrid = styled.table<CalendarGridAria['gridProps']>`
  overflow: hidden;
  width: 100%;
`;

function CalendarGrid({ state, direction, ...props }: AriaCalendarGridProps & { state: CalendarState; direction: number }) {
  const xValue = 270;
  const { locale } = useRouter();
  const startDate = state.visibleRange.start;
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  const variants = {
    initial: (direction: number) => {
      return { x: xValue * direction };
    },
    animate: { x: 0 },
    exit: (direction: number) => {
      return { x: xValue * direction * -1 };
    },
  };

  return (
    <StyledCalendarGrid {...gridProps}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <AnimatePresence exitBeforeEnter={true} custom={direction} initial={false}>
        <motion.tbody key={startDate.toString()} variants={variants} animate={'animate'} initial={'initial'} transition={{ duration: 0.15 }} exit={'exit'} custom={direction}>
          {/*By using an array of length 6, the datepicker will always stay the same size as 6 is the maximum number of weeks a month can span*/}
          {/*To make the datepicker only use required height, change 6 to weeksInMonth*/}
          {[...new Array(6).keys()].map((weekIndex) => (
            // {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
            <tr key={weekIndex}>
              {state
                .getDatesInWeek(weekIndex, startDate)
                .map((date, i) => (date ? <CalendarCell key={date.day} state={state} date={date} currentMonth={startDate} /> : <td key={i} />))}
            </tr>
          ))}
        </motion.tbody>
      </AnimatePresence>
    </StyledCalendarGrid>
  );
}

export default CalendarGrid;
